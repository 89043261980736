import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import styled, { css } from 'styled-components';
import Footer from '../components/Footer';

import {
  Image,
  UnderlinedHeader,
  Text,
  Box,
  Flex,
  Link,
  Header,
  Grid,
  InlineList,
  InlineListItem,
} from '../components/core';
import Layout from '../components/layout';
import Nav from '../components/Nav';

// using 'github-buttons/dist/react' didn't work since it references window (problems during build)
const GitHubStarButton = ({ githubUrl }) => {
  const [user, repo] = githubUrl.split('github.com/')[1].split('/');
  return (
    <iframe
      src={`https://ghbtns.com/github-btn.html?user=${user}&repo=${repo}&type=star&count=true`}
      title={`GitHub Stars for @${user}/${repo}`}
      frameBorder="0"
      scrolling="0"
      width="170px"
      height="20px"
    />
  );
};

const Section = ({ header, children, ...other }) => (
  <Box pt={3} pb={6} px={3} {...other}>
    <Box pl={3}>
      <UnderlinedHeader my={3}>{header}</UnderlinedHeader>
    </Box>
    {children}
  </Box>
);

const GridSection = ({ gridSize = 4, children, ...other }) => (
  <Section {...other}>
    <Grid p={3} gridGap={4} gridTemplateColumnsFill={gridSize}>
      {children}
    </Grid>
  </Section>
);

const GridItem = ({ url, banner, title, subhead }) => (
  <Text lineHeight={1.5}>
    <Link to={url}>
      {banner && <Image mb={2} fluid={banner.childImageSharp.fluid} />}
      <Text as="span">{title}</Text>
    </Link>
    <Text color={'gray.6'} fontSize={1} mt={1}>
      {subhead}
    </Text>
  </Text>
);

const GitHubGridItem = ({ url, github, title, npmId = title, subhead }) => (
  <Text lineHeight={1.5}>
    <Link to={url}>
      <Text as="span">{title}</Text>
    </Link>
    <Box mt={2}>
      <GitHubStarButton githubUrl={github}>Star</GitHubStarButton>
    </Box>
    <Box mt={1}>
      <img
        className="my-1"
        alt="npm"
        src={`https://img.shields.io/npm/v/${npmId}.svg`}
      />{' '}
      <img
        className="my-1"
        alt="npm"
        src={`https://img.shields.io/npm/dm/${npmId}.svg`}
      />
    </Box>
    <Text color={'gray.6'} fontSize={1}>
      {subhead}
    </Text>
  </Text>
);

const IndexPage = ({ data }) => {
  const { blog, projects, experiments, page } = data;

  return (
    <Layout hideNavTitle hideNav>
      <Helmet>
        <meta property="og:type" content="website" />
      </Helmet>
      <div>
        <div className="max-w-md px-5 pt-8 pb-12 mx-auto text-lg">
          <div>
            <div
              className="inline-block px-3 py-2 mb-12 text-2xl font-bold text-white bg-black rounded-lg"
              style={{ boxShadow: '5px 6px var(--cyan-400)' }}
            >
              Peter Beshai
            </div>
            <p className="mb-4 text-lg">
              Hey, I'm{' '}
              <span className="font-semibold text-gray-800 funky-underline">
                Peter
              </span>
              !
            </p>
            <p className="text-lg">
              I'm a{' '}
              <span className="font-semibold text-pink-400">
                software engineer
              </span>{' '}
              and <span className="font-semibold text-cyan-500">designer</span>{' '}
              with a passion for making high performance, delightful user
              experiences on the web, typically involving data visualization.
            </p>
          </div>
          <div className="mt-8 text-lg">
            <h3 className="font-semibold text-gray-700">Find me online</h3>
            <div className="grid grid-cols-3 leading-loose">
              <div>
                <Link to="mailto:peter.beshai@gmail.com">Email</Link>
              </div>
              <div>
                <Link to="https://twitter.com/pbesh">Twitter</Link>
              </div>
              <div>
                <Link to="https://twitch.tv/pbeshai">Twitch</Link>
              </div>
              <div>
                <Link to="https://github.com/pbeshai">GitHub</Link>
              </div>
              <div>
                <Link to="https://instagram.com/pbeshasketch">Instagram</Link>
              </div>
              <div>
                <Link to="https://www.linkedin.com/in/pbeshai">LinkedIn</Link>
              </div>
            </div>
          </div>

          <div className="mt-8 leading-loose">
            <h3 className="font-semibold text-gray-700">Jump to a section</h3>
            <Nav showTitle={false} hideHome />
          </div>
        </div>

        <GridSection id="writing" header="Writing" bg="white" gridSize={3}>
          {blog &&
            blog.edges.map(({ node }) => {
              return (
                <GridItem
                  key={node.id}
                  url={node.frontmatter.url || node.fields.slug}
                  banner={node.frontmatter.banner}
                  title={node.frontmatter.title}
                  subhead={
                    <>
                      {node.frontmatter.date}
                      <Text color={'gray.6'} fontSize={1} display="inline">
                        {node.frontmatter.host &&
                          ` on ${node.frontmatter.host}`}
                      </Text>
                    </>
                  }
                />
              );
            })}
        </GridSection>

        <GridSection
          id="projects"
          header="Projects"
          bg="gray.0"
          gridSize={[3, 4]}
        >
          {projects &&
            projects.edges.map(({ node }) => {
              return (
                <GridItem
                  key={node.id}
                  url={node.frontmatter.url || node.fields.slug}
                  banner={node.frontmatter.banner}
                  title={node.frontmatter.title}
                  subhead={
                    <>
                      {node.frontmatter.date}
                      <Text color={'gray.6'} fontSize={1} display="inline">
                        {node.frontmatter.company &&
                          ` at ${node.frontmatter.company}`}
                      </Text>
                    </>
                  }
                />
              );
            })}
        </GridSection>

        <GridSection
          id="experiments"
          header="Experiments"
          bg="white"
          gridSize={[2, 2]}
        >
          {experiments &&
            experiments.edges.map(({ node }) => {
              return (
                <GridItem
                  key={node.id}
                  url={node.frontmatter.url || node.fields.slug}
                  banner={node.frontmatter.banner}
                  title={node.frontmatter.title}
                  subhead={node.frontmatter.date}
                />
              );
            })}
        </GridSection>

        <GridSection
          id="open-source"
          header="Open Source"
          bg="gray.0"
          gridSize={3}
        >
          {page.frontmatter.open_source &&
            page.frontmatter.open_source.map((item) => {
              return (
                <GitHubGridItem
                  key={item.url}
                  url={item.url}
                  github={item.github}
                  title={item.title}
                  npmId={item.npm_id ?? item.title}
                  subhead={item.description}
                />
              );
            })}
        </GridSection>
      </div>
    </Layout>
  );
};

export default IndexPage;

// need regex filter on blog to make sure we do not include README.md near demos
export const query = graphql`
  query {
    page: markdownRemark(frontmatter: { path: { eq: "/" } }) {
      fields {
        slug
      }
      frontmatter {
        open_source {
          url
          title
          description
          github
          npm_id
        }
      }
    }

    blog: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fileAbsolutePath: { regex: "//blog/(.*)/index.md/" }
        frontmatter: { layout: { ne: "demo" } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            layout
            title
            date(formatString: "MMMM D, YYYY")
            excerpt
            host
            url
            banner {
              childImageSharp {
                fluid(maxWidth: 540, maxHeight: 340, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }

    projects: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "//projects/(.*)/index.md/" } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            date(formatString: "YYYY")
            company
            url
            banner {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }

    experiments: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "//experiments/(.*)/index.md/" } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            date(formatString: "MMMM YYYY")
            url
            banner {
              childImageSharp {
                fluid(maxWidth: 540, maxHeight: 340, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`;
